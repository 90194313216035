<script>
import ResourceTable from '@shell/components/ResourceTable';
import { MANAGEMENT } from '@shell/config/types';
import { filterOnlyKubernetesClusters } from '@shell/utils/cluster';
import ResourceFetch from '@shell/mixins/resource-fetch';
export default {
  name:       'ListMgmtClusters',
  components: { ResourceTable },
  mixins:     [ResourceFetch],

  props: {
    resource: {
      type:     String,
      required: true,
    },
    schema: {
      type:     Object,
      required: true,
    },
  },

  async fetch() {
    await this.$fetchType(this.resource);
  },
  computed: {
    filteredRows() {
      return filterOnlyKubernetesClusters(this.rows);
    }
  },
  // override with relevant info for the loading indicator since this doesn't use it's own masthead
  $loadingResources() {
    return {
      loadResources:     [MANAGEMENT.CLUSTER],
      loadIndeterminate: true, // results are filtered so we wouldn't get the correct count on indicator...
    };
  },
};
</script>

<template>
  <ResourceTable :schema="schema" :rows="filteredRows" :headers="$attrs.headers" :group-by="$attrs.groupBy" :loading="loading" />
</template>
